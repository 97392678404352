import { useEffect } from 'react';

import { AnalyticsStore } from '@cointracker/analytics';
import * as VanillaCookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { useStore } from 'zustand';
import './CookieConsent.css';

interface CookieConsentProps {
  isDarkTheme: boolean;
  showManageButton?: boolean;
  loading?: boolean;
  onConsent?: ({ cookie }: { cookie: CookieConsent.CookieValue }) => void;
}
export const CookieConsent = ({
  isDarkTheme,
  showManageButton,
  loading,
  onConsent,
}: CookieConsentProps) => {
  const { setUserConsent } = useStore(AnalyticsStore);

  useEffect(() => {
    if (isDarkTheme) {
      document.body.classList.add('cc--darkmode');
    } else {
      document.body.classList.remove('cc--darkmode');
    }
  }, [isDarkTheme]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const isLocalStorageAvailable = !!window.localStorage;
    VanillaCookieConsent.run({
      cookie: {
        useLocalStorage: isLocalStorageAvailable,
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: false,
        },
      },
      onConsent: ({ cookie }) => {
        onConsent?.({ cookie });
        setUserConsent(cookie.categories);
      },
      onChange: ({ cookie }) => {
        onConsent?.({ cookie });
        setUserConsent(cookie.categories);
      },
      guiOptions: {
        consentModal: {
          layout: 'box inline',
          position: 'bottom right',
          flipButtons: true,
        },
        preferencesModal: {
          layout: 'box',
          position: 'right',
          equalWeightButtons: true,
          flipButtons: true,
        },
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: '🍪 Cookie consent',
              description:
                'We and our partners use cookies and other tools to process information about your use of our site to provide the best user experience and help with marketing. By agreeing, you accept the use of cookies and <a href="/terms">our terms</a>. View our <a href="/privacy">privacy policy</a> for details.',
              acceptAllBtn: 'Agree',
              showPreferencesBtn: showManageButton ? 'Manage cookies' : '',
            },
            // Needed for the component to work, but we currently don't show a preferences modal
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  description:
                    'Cookies are small files that websites place on users’ devices to improve marketing and user experience, for example, by remembering your preferences.',
                },
                {
                  title: 'Necessary cookies',
                  description:
                    'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Performance and analytics',
                  description:
                    'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics',
                },
              ],
            },
          },
        },
      },
    });
  }, [showManageButton, onConsent, setUserConsent, loading]);

  return null;
};
